<template>
  <section class="footer">
    <div class="w">
      <div class="logo">
        <img src="@/assets/imgs/logo2.png">
      </div>
      <div class="md">
        <div class="links">
          <ul>
            <li>
              <div class="title">新手指南</div>
              <a>经营资质</a>
              <a>免责声明</a>
              <a>园区介绍</a>
              <a>常见问题</a>
              <a>注册流程</a>
            </li>
            <li>
              <div class="title">服务指南</div>
              <a @click="navTo('/serviceTender')">招采投标</a>
              <a @click="navTo('/servicePrice')">价格指数</a>
              <a>服务大厅</a>
            </li>
            <li>
              <div class="title">交易安全</div>
              <a>服务保障</a>
              <a>用户协议</a>
            </li>
            <li>
              <div class="title">售后服务</div>
              <a>售后服务</a>
              <a>联系客服</a>
              <a>联系方式</a>
            </li>
            <li>
              <div class="title">关于我们</div>
              <a>关于我们</a>
            </li>
          </ul>
        </div>
        <div class="imgs">
          <!-- <ul>
            <li>
              <img src="@/assets/imgs/code1.png">
              <p>微信公众号</p>
            </li>
            <li>
              <img src="@/assets/imgs/code2.png">
              <p>招投标小程序</p>
            </li>
            <li>
              <img src="@/assets/imgs/code2.png">
              <p>微信小程序</p>
            </li>
            <li>
              <img src="@/assets/imgs/code1.png">
              <p>Android下载</p>
            </li>
          </ul> -->
          <div class="remark">
            <img src="@/assets/imgs/comment.png">
            <p>点这里给我们留言...</p>
          </div>
        </div>
      </div>
      <div class="contact">
        <div class="item">
          <img src="@/assets/imgs/foot1.png">
          <p>0558-5588508</p>
        </div>
        <div class="item">
          <img src="@/assets/imgs/foot2.png">
          <p>bzxh@bzcjhg.com</p>
        </div>
        <div class="item">
          <img src="@/assets/imgs/foot3.png">
          <p>地址：安徽省亳州市高新区古井大道与合欢路交叉口城建集团中药材产业园</p>
        </div>
      </div>
    </div>
    <div class="beian-area">
      <div>
        <span class="cop2">互联网药品信息服务资格证书</span>
        <span class="cop2">(皖)-经营性-2022-0020</span>
      </div>

      <div class="copright">
        <a class="beian" href="https://beian.miit.gov.cn/"
          target="_blank">备案号：皖ICP备34160202000846号，皖公网安备34160202000846</a>
        <img src="https://www.beian.gov.cn/img/new/gongan.png"
          style="vertical-align: middle; margin: 0 2px" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mixins: [],
  components: {},
  data() {
    return {}
  },
  created() {},
  methods: {
    navTo(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang='scss' scoped>
.footer {
  font-size: 12px;
  padding-top: 50px;
  background-color: rgb(9, 22, 36);
  .md {
    padding-top: 40px;
    display: flex;
    padding-bottom: 40px;
  }
  .links {
    color: #fff;
    ul {
      display: flex;
      li {
        margin-right: 50px;
      }
    }
    .title {
      margin-bottom: 20px;
      font-size: 14px;
    }
    a {
      color: #9ea5b6;
      display: block;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .imgs {
    ul {
      margin-bottom: 30px;
      display: flex;
      li {
        margin-right: 50px;
        p {
          color: #a0a0b3;
          margin-top: 10px;
        }
      }
    }
  }
  .remark {
    display: flex;
    align-items: center;
    color: #a0a0b3;

    border-bottom: 1px solid #a0a0b3;
    width: 200px;
    padding-bottom: 5px;
    img {
      margin-right: 10px;
    }
  }
  .contact {
    display: flex;
    color: #515a6e;
    padding-bottom: 20px;
    .item {
      display: flex;
      margin-right: 80px;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
  }
  .beian-area {
    border-top: 1px solid #ccc;
    color: #515a6e;
    text-align: center;
    padding: 20px 0;
    .copright {
      margin-top: 10px;
    }
    .beian {
      color: #515a6e;
    }
  }
}
</style>
