var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"footer"},[_c('div',{staticClass:"w"},[_vm._m(0),_c('div',{staticClass:"md"},[_c('div',{staticClass:"links"},[_c('ul',[_vm._m(1),_c('li',[_c('div',{staticClass:"title"},[_vm._v("服务指南")]),_c('a',{on:{"click":function($event){return _vm.navTo('/serviceTender')}}},[_vm._v("招采投标")]),_c('a',{on:{"click":function($event){return _vm.navTo('/servicePrice')}}},[_vm._v("价格指数")]),_c('a',[_vm._v("服务大厅")])]),_vm._m(2),_vm._m(3),_vm._m(4)])]),_vm._m(5)]),_vm._m(6)]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/imgs/logo2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"title"},[_vm._v("新手指南")]),_c('a',[_vm._v("经营资质")]),_c('a',[_vm._v("免责声明")]),_c('a',[_vm._v("园区介绍")]),_c('a',[_vm._v("常见问题")]),_c('a',[_vm._v("注册流程")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"title"},[_vm._v("交易安全")]),_c('a',[_vm._v("服务保障")]),_c('a',[_vm._v("用户协议")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"title"},[_vm._v("售后服务")]),_c('a',[_vm._v("售后服务")]),_c('a',[_vm._v("联系客服")]),_c('a',[_vm._v("联系方式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"title"},[_vm._v("关于我们")]),_c('a',[_vm._v("关于我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgs"},[_c('div',{staticClass:"remark"},[_c('img',{attrs:{"src":require("@/assets/imgs/comment.png")}}),_c('p',[_vm._v("点这里给我们留言...")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/imgs/foot1.png")}}),_c('p',[_vm._v("0558-5588508")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/imgs/foot2.png")}}),_c('p',[_vm._v("bzxh@bzcjhg.com")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/imgs/foot3.png")}}),_c('p',[_vm._v("地址：安徽省亳州市高新区古井大道与合欢路交叉口城建集团中药材产业园")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"beian-area"},[_c('div',[_c('span',{staticClass:"cop2"},[_vm._v("互联网药品信息服务资格证书")]),_c('span',{staticClass:"cop2"},[_vm._v("(皖)-经营性-2022-0020")])]),_c('div',{staticClass:"copright"},[_c('a',{staticClass:"beian",attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("备案号：皖ICP备34160202000846号，皖公网安备34160202000846")]),_c('img',{staticStyle:{"vertical-align":"middle","margin":"0 2px"},attrs:{"src":"https://www.beian.gov.cn/img/new/gongan.png"}})])])
}]

export { render, staticRenderFns }